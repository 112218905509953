body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


#loginBackground {
  /* Set rules to fill background */
  min-height: 100%;
  min-width: 100%;
  

  
  /* Set up positioning */
  position: fixed;
  top: 0;
  left: 0;

  /* background-image: linear-gradient(to bottom, white,#8a9ab8 50%, #8a9ab8 50%,#454648); */
  /* background-image: linear-gradient(to bottom, #4369b2,#4369b2 50%, #4369b2 50%,#4369b2 ); */
  background-image: url('/public/new-construction-1024x768.png');
  background-size: cover;
  background-repeat: no-repeat;

  display: flex;
  flex-direction: column;
  align-items: center;
}


#loginContainer {
 /* Set rules to fill background */
 min-height: 100%;
 min-width: 100%;
 

 
 /* Set up positioning */
 position: fixed;
 top: 0;
 left: 0;

 display: flex;
 flex-direction: row;
 align-items: center;
 justify-content: center;
}

/* custom - remove dropdown carat on NavDropdown react bootstrap */
.dropdown-toggle::after {
  display: none !important
}

.custom-button {
  background-color: #f7941d; 
  color: white; 
  border: none; 
  padding: .5rem; 
  border-radius: 5px;
}

.custom-button:hover {
  background-color: #fab664;
}
